import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Tipo } from 'src/app/app.models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-carousel-tipos',
  templateUrl: './carousel-tipos.component.html',
  styleUrls: ['./carousel-tipos.component.scss']
})

export class CarouselTiposComponent implements OnInit {

  @Input('slides') slides: Tipo[] = [];
  
  public urlImages = environment.urlImages;
  public currentSlide; 
  public showArrows:boolean = false;
  public config: SwiperConfigInterface = {};  

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){    
    this.initCarousel();
  }

  ngOnChanges(){
    if(this.slides?.length > 0){
      this.currentSlide = this.slides[0];  
    }
  }

  public initCarousel(){
    this.config = {
      spaceBetween: 0,         
      navigation: true,
      pagination: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      autoplay: false,
      centerInsufficientSlides: true,
      speed: 300,
      effect: "slide",  
      on: {
        init: function() {
          this.checkArrow();
        },
        resize: function () {
          this.checkArrow();
        }
      },
      breakpoints: {
        320: {
          slidesPerView: 3,
          spaceBetween: 20
        },
        480: {
          slidesPerView: 4,
          spaceBetween: 30
        },
        640: {
          slidesPerView: 6,
          spaceBetween: 40
        },
        1024: {
          slidesPerView: 8,
          spaceBetween: 40
        }        
      }      
    }
  }

  ngOnDestroy(){  
  }
  
  public onIndexChange(index: number) {  
    this.currentSlide = this.slides[index];
  }

  public checkArrows(event) {  
    if(event.pagination.bullets.length>1){
      this.showArrows = true;
    }else{
      this.showArrows = false;
    }
  }

}
