import { Menu } from './menu.model';

export const horizontalMenuItems = [ 
    new Menu (1, 'Inicio', '/', null, null, false, 0),
    new Menu (2, 'Propiedades', '/propiedades', null, null, false, 0),
    new Menu (3, 'Ofertas', '/propiedades-oferta', null, null, false, 0),
    new Menu (4, 'Contacto', '/contacto', null, null, false, 0),
    new Menu (5, 'Inversionistas', '/inversionistas', null, null, false, 0),
]

export const verticalMenuItems = [ 
    new Menu (1, 'Inicio', '/', null, null, false, 0),
    new Menu (2, 'Propiedades', '/propiedades', null, null, false, 0),
    new Menu (3, 'Ofertas', '/propiedades-oferta', null, null, false, 0),
    new Menu (4, 'Contacto', '/contacto', null, null, false, 0),
    new Menu (5, 'Inversionistas', '/inversionistas', null, null, false, 0),
]