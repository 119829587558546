import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Settings, AppSettings } from '../../app.settings';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-map-carousel',
  templateUrl: './map-carousel.component.html',
  styleUrls: ['./map-carousel.component.scss'] 
})
export class MapCarouselComponent implements OnInit {
  @Input('fotos') fotos = [];
  
  public config: SwiperConfigInterface = {};  
  public currentSlide; 
  public settings: Settings;
  public urlImages = environment.urlImages;

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };  

  constructor(public appSettings:AppSettings) { 
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
  }

  ngAfterViewInit(){    
    this.initCarousel();
  }

  ngOnChanges(){
  }

  public initCarousel(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: false,
      pagination: this.pagination,
      navigation: true,
      grabCursor: true,        
      loop: false,
      preloadImages: true,
      lazy: false,
      speed: 500,
      effect: "slide"
    }
  }

  ngOnDestroy(){  
    setTimeout(() => {
      this.settings.contentOffsetToTop = false;
    });  
  }
  
  public onIndexChange(index: number) {  
    
  }
}