import { Component, OnInit, Input, ViewChild, SimpleChange } from '@angular/core';
import { SwiperDirective, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper'; 
import { Property } from '../../app.models';
import { Settings, AppSettings } from '../../app.settings';
import { getValue, getClearAttr } from 'src/app/theme/utils/utils';

import { AppService } from '../../app.service'; 
import { environment } from 'src/environments/environment';
import { Attributes } from 'src/app/app.models';

@Component({
  selector: 'app-property-item',
  templateUrl: './property-item.component.html',
  styleUrls: ['./property-item.component.scss'] 
})

export class PropertyItemComponent implements OnInit {
  @Input() property: Property;
  @Input() viewType: string = "grid";
  @Input() tipo: string = "";
  @Input() viewColChanged: boolean = false; 
  @Input() fullWidthPage: boolean = true;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;

  public column:number = 4;
  public url: string;
  public config: SwiperConfigInterface = {};
  public settings: Settings;
  public urlImages = environment.urlImages;
  public getValue = getValue;
  public attributes = Attributes;
  public getClearAttr = getClearAttr;
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  constructor(public appSettings:AppSettings, public appService:AppService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() { 
    this.url='/propiedades/propiedad';
    if(this.tipo=='litigio') this.url='/inversionistas/propiedad';
    if(this.tipo=='oferta') this.url='/propiedades-oferta/propiedad';
  }

  ngAfterViewInit(){
    this.initCarousel();
  } 
 
  ngOnChanges(changes: {[propKey: string]: SimpleChange}){  
    if(changes.viewColChanged){
      this.getColumnCount(changes.viewColChanged.currentValue);
      if(!changes.viewColChanged.isFirstChange()){
        if(this.property.fotos.length > 1){     
           this.directiveRef.update();  
        } 
      }
    }  
  }

  public getColumnCount(value){
    if(value == 25){
      this.column = 4;
    }
    else if(value == 33.3){
      this.column = 3;
    }
    else if(value == 50){
      this.column = 2
    }
    else{
      this.column = 1;
    }
  }


  public initCarousel(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: false,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: true,
      preloadImages: false,
      lazy: true,  
      nested: true,
      speed: 500,
      effect: "slide"
    }
  }

  public getTipo(item){
    let url='/propiedades/propiedad';
    if(item.litigio) url='/inversionistas/propiedad';
    if(item.oferta) url='/propiedades-oferta/propiedad';
    return url;
  }

}
