import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Settings, AppSettings } from '../../app.settings';
import { environment } from 'src/environments/environment';
import { Property } from 'src/app/app.models';

@Component({
  selector: 'app-header-carousel',
  templateUrl: './header-carousel.component.html',
  styleUrls: ['./header-carousel.component.scss'] 
})
export class HeaderCarouselComponent implements OnInit {
  @Input('slides') slides: Property[] = [];
  @Input('contentOffsetToTop') contentOffsetToTop; 
  @Input('fullscreen') fullscreen: boolean = false;
  
  public config: SwiperConfigInterface = {};  
  public currentSlide; 
  public settings: Settings;
  public urlImages = environment.urlImages;

  constructor(public appSettings:AppSettings) { 
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    if(this.contentOffsetToTop){
      setTimeout(() => {
        this.settings.contentOffsetToTop = this.contentOffsetToTop;
      });
    } 
  }

  ngAfterViewInit(){    
    this.initCarousel();
  }

  ngOnChanges(){
    if(this.slides?.length > 0){
      this.currentSlide = this.slides[0];  
    }
  }

  public initCarousel(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: true,
      preloadImages: false,
      lazy: true,     
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: "slide"
    }
  }

  ngOnDestroy(){  
    setTimeout(() => {
      this.settings.contentOffsetToTop = false;
    });  
  }
  
  public onIndexChange(index: number) {  
    this.currentSlide = this.slides[index];
  }

  public getPrice(valores){    
    if(valores!=null){
      let valor = valores.find(valor => valor.nombre === 'Precio');
      let moneyFormat = Intl.NumberFormat('en-US');
      return moneyFormat.format(parseFloat(valor?.valor.replace(/,/g, '')));
    }else{
      return '';
    }
  }

  public getDireccion(valores){    
    if(valores!=null){
      let valor = valores.find(valor => valor.atributo === 13);
      return valor?.valor;
    }else{
      return '';
    }
  }

  public getTipo(item){
    let url='/propiedades/propiedad';
    if(item.litigio) url='/inversionistas/propiedad';
    if(item.oferta) url='/propiedades-oferta/propiedad';
    return url;
  }
}