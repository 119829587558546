import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router"
import { Amenidad, Atributo, Equipamiento, Estado, Legals, Municipio, Servicio, Tipo } from 'src/app/app.models';
import { AppService } from '../../app.service'; 

@Component({
  selector: 'app-properties-search',
  templateUrl: './properties-search.component.html',
  styleUrls: ['./properties-search.component.scss']
})
export class PropertiesSearchComponent implements OnInit {
  @Input() variant:number = 1;
  @Input() tipoId:any;
  @Input() estadoId:any;
  @Input() municipioId:any;
  @Input() municipioPlaceholder:string;
  @Input() isHome:boolean = false;
  @Input() mapa:boolean = false;
  @Input() seccion:any;
  @Input() vertical:boolean = false;
  @Input() searchOnBtnClick:boolean = false;
  @Input() removedSearchField:string;
  @Input() resetForm: Function;
  @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();
  public showMore: boolean = false;
  public form: FormGroup;
  public tipos: Tipo[] = []; 
  public atributos: Atributo[] = [];
  public servicios: Servicio[] = [];
  public amenidades: Amenidad[] = [];
  public legals: Legals[] = [];
  public equipamientos: Equipamiento[] = [];
  public estados: Estado[] = []; 
  public municipios: Municipio[] = []; 
  public finished: boolean = false; 

  constructor(public appService:AppService, public fb: FormBuilder,private router: Router,private route: ActivatedRoute) {}

  ngOnInit() {
    this.form = this.fb.group({tipoId: this.tipoId, estadoId: this.estadoId, municipioId: {value: this.municipioId, disabled: true}});
    this.getTipos();
    this.getEstados();
    this.getFiltros();
  }
 
  public buildAtributos(tipoId=null) {
    const arr = [];
    this.atributos.map(atributo => { 
      arr['atributo_'+atributo.id] = (this.buildTypeAtributo(atributo));
    });
    this.servicios?.map(servicio => { 
      arr['servicio_'+servicio.id] = null;
    });
    this.amenidades?.map(amenidad => { 
      arr['amenidad_'+amenidad.id] = null;
    });
    this.equipamientos?.map(equipamiento => { 
      arr['equipamiento_'+equipamiento.id] = null;
    })

    if(this.seccion=='-litigio'){
      this.legals?.map(legals => { 
        arr['legals_'+legals.id] = null;
      })
    }
    arr['tipoId']=tipoId;
    arr['estadoId']=null;
    arr['municipioId']=null;
    this.municipios = null;
    this.municipioPlaceholder = '';
    this.form.get('municipioId').disable();
    return arr;
  }

  private buildTypeAtributo(atributo:Atributo){
    if(atributo.tipo_filtro!='range'){
      return null;
    }else{
      return this.fb.group({
        from: null,
        to: null 
      })
    }
  }
  
  public getTipos(){
    this.appService.getTipos().subscribe(res =>{
      this.tipos = res;
    })
  }

  public getEstados(){
    this.appService.getEstados().subscribe(res =>{
      this.estados = res;
      if(this.estadoId!=null){this.getMunicipios(this.estadoId.id)};
    })
  }

  public getMunicipios(estado_id){
    this.appService.getMunicipios(estado_id).subscribe(res =>{
      this.municipios = res;
      this.form.controls['municipioId'].setValue(null);
      this.form.get('municipioId').enable();
      this.municipioPlaceholder = 'Selecciona un municipio';
    })
  }

  public getAtributos(){
    this.appService.getAtributos(true, this.tipoId).subscribe(res =>{
      this.atributos = res;
      if(this.tipoId!='' && this.tipoId!=null){
        this.getServicios();
      }else{
        this.buildForm();
      }
    })
  }

  public getServicios(){
    this.appService.getServicios(this.tipoId).subscribe(res =>{
      this.servicios = res;
      this.getAmenidades();
    })
  }

  public getAmenidades(){
    this.appService.getAmenidades(this.tipoId).subscribe(res =>{
      this.amenidades = res;
      this.getEquipamientos();
    })
  }

  public getEquipamientos(){
    this.appService.getEquipamientos(this.tipoId).subscribe(res =>{
      this.equipamientos = res;
      this.buildForm();
    })
  }

  public getLegals(){
    this.appService.getLegals().subscribe(res =>{
      this.legals = res;
      this.getAtributos();
    })
  }

  public buildForm(){
    let filtros = this.buildAtributos();
    //
    filtros['tipoId'] = this.tipos?.find(i => i.id == this.tipoId);
    filtros['estadoId'] = this.estados?.find(i => i.id == this.estadoId);
    filtros['municipioId'] = this.municipios?.find(i => i.id == this.municipioId);
    //
    this.form = this.fb.group(filtros);
    this.form.get('municipioId').disable();
    this.finished = true;
    this.onSearchChange.emit(this.form);
  }

  ngOnChanges(){ 
    if(this.removedSearchField){ 
      if(this.removedSearchField=='estadoId'){
        if(this.resetForm) this.resetForm();
        this.municipios = null;
        this.form.controls['municipioId'].reset();
        this.form.controls['municipioId'].disable();
        this.municipioPlaceholder = '';
      }
      if(this.removedSearchField=='tipoId'){

        if(this.resetForm) this.resetForm();
        this.form.reset();
        
        if(this.mapa){
          this.router.navigate([((this.seccion=='')?'propiedades':this.seccion)+'/mapa/'])
        }else{
          this.router.navigate(['/propiedades'+this.seccion])
        }
      }
      if(this.removedSearchField.indexOf(".") > -1){
        let arr = this.removedSearchField.split(".");
        this.form.controls[arr[0]].reset();
      } 
      else if(this.removedSearchField.indexOf(",") > -1){        
        let arr = this.removedSearchField.split(","); 
        this.form.controls[arr[0]]['controls'][arr[1]]['controls']['selected'].setValue(false);  
      }
      else{
        this.form.controls[this.removedSearchField].reset();
      }  
    }  
  }

  onChangeEstado(estado){
    if(this.resetForm) this.resetForm();
    this.getMunicipios(estado.id);
    this.municipioId = null;
  }

  onChangeTipo(tipo){
      this.amenidades = [];
      this.servicios = [];
      this.equipamientos = [];
      this.legals = [];
      this.finished = false;  
      this.tipoId = tipo.id;
      this.estadoId = null;
      this.municipioId = null;
      this.form.reset({
        tipoId: tipo, 
      });
      this.getTipos();
      this.getEstados();
      this.getFiltros();   
  }

  public reset(){ 
    if(this.resetForm) this.resetForm();   
    this.form.reset(this.buildAtributos()); 
    this.getFiltros();
  }

  public getFiltros(){
    if(this.seccion=='-litigio'){
      this.getLegals();
    }else{
      this.getAtributos();
    }    
  }

  public reset_reebot(){  
    if(this.resetForm) this.resetForm();
    this.amenidades = [];
    this.equipamientos = [];
    this.legals = [];
    this.servicios = [];
    this.form.reset(this.buildAtributos()); 
    if(this.mapa){
      this.router.navigate([((this.seccion=='')?'propiedades':this.seccion)+'/mapa/']);
    }else{
      this.router.navigate(['/propiedades'+this.seccion]);
    }
  }

  public search(){
    this.onSearchClick.emit(); 
  }

  public getAppearance(){
    return (this.variant != 3) ? 'outline' : '';
  }
  public getFloatLabel(){
    return (this.variant == 1) ? 'always' : '';
  }
  public getInputType(type:string){
    let return_type:string;
    switch(type) { 
      case 'text': { 
         return_type = 'text'; 
         break; 
      } 
      case 'integer': { 
         return_type = 'text'; 
         break; 
      } 
      case 'float': { 
        return_type = 'number'; 
        break; 
     }       
      default: { 
        return_type = 'text'; 
         break; 
      } 
   } 
   return return_type;
  }

  public compareTiposObject(object1: any, object2: any){
    return object1 && object2 && object1.id == object2.id;
  }

}
