// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: '',
  api: 'https://inmuebles-api.narrative.click/',
  urlImages:'https://inmuebles-api.narrative.click',
  mapKey: 'AIzaSyAam3ZG37BetyOGA_IpSW77tMaEnbbM6zA'
};
