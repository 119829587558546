import { Attributes } from 'src/app/app.models';

export function parseLocation(location: string, option: string = 'lat') {
    if(location=='' || location==null) return null;
    if(location.indexOf(",")<0) return null;
    let comma = location.indexOf(",");
    let loc = (comma!=-1)?location.split(","):location.split(" ");
    if (option == 'lat') {
        return Number(loc[0].trim());
    } else {
        return Number(loc[1].trim());
    }
}

export function parseVimeo(url) {
    const re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
    const matches = re.exec(url);
    return matches && "https://player.vimeo.com/video/" + matches[1];
}

export function slugify(input: string): string {
    return input.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        //.replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

export function getValue(valores, slug) {
    if (valores != null) {
        let valor = valores.find(valor => valor.slug === slug);
        if(slug=='price'){
            let moneyFormat = Intl.NumberFormat('en-US');
            return moneyFormat.format(parseFloat(valor?.valor.replace(/,/g, '')));
        }else{
            return valor?.valor;
        }
    } else {
        return '';
    }
}

export function getClearAttr(valores) {
    let attributes = Attributes;
    let attributesArray = Object.values(attributes);
    if (valores != null) {
        let clear_valores = valores.filter(function (currentElement) {
            if(attributesArray.indexOf(currentElement.slug)> -1){
                return false
            }else{
                if(Number.isInteger(parseInt(currentElement.valor)) && currentElement.slug!='cp'){
                    let moneyFormat = Intl.NumberFormat('en-US');
                    currentElement.valor =  moneyFormat.format(parseFloat(currentElement.valor .replace(/,/g, '')));
                }
                return true;
            }
        });
        return clear_valores;
    } else {
        return '';
    }
}

export function getLocation(location, option) {
    return parseLocation(location, option);
}

export function print() {
    window.print();
    location.reload();
    return false;
}