import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Amenidad, Atributo, Equipamiento, Legals, Servicio } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-properties-search-results-filters',
  templateUrl: './properties-search-results-filters.component.html',
  styleUrls: ['./properties-search-results-filters.component.scss']
})
export class PropertiesSearchResultsFiltersComponent implements OnInit, OnChanges {
  @Input() searchFields: any;
  @Input() isHome:boolean;
  @Output() onRemoveSearchField: EventEmitter<any> = new EventEmitter<any>();
  public servicios: Servicio[]; 
  public atributos: Atributo[]; 
  public amenidades: Amenidad[]; 
  public equipamientos: Equipamiento[]; 
  public legals: Legals[]; 

  constructor(public appService:AppService) { } 

  ngOnInit() { 
    this.getAtributos();
  }

  ngOnChanges() { 
    this.filterSearchField(this.searchFields);
  }

  public remove(field){
    this.onRemoveSearchField.emit(field);
  }

  public getAtributos(){
    this.appService.getAllAtributos().subscribe(res =>{
      this.atributos = res;
      this.getAmenidades();
    })
  }

  public getAmenidades(){
    this.appService.getAmenidades().subscribe(res =>{
      this.amenidades = res;
      this.getEquipamientos();
    })
  }

  public getEquipamientos(){
    this.appService.getEquipamientos().subscribe(res =>{
      this.equipamientos = res;
      this.getServicios();
    })
  }

  public getServicios(){
    this.appService.getServicios().subscribe(res =>{
      this.servicios = res;
      this.getLegals();
    })
  }

  public getLegals(){
    this.appService.getLegals().subscribe(res =>{
      this.legals = res;
      this.filterSearchField(this.searchFields);
    })
  }

  public filterSearchField(searchFields){
    if(searchFields!=null){
      return Object.entries(searchFields).reduce((acc,elm)=>{
        const [k,v] = elm;
        if (v!=null) {
          if(k!='estadoId' && k!='municipioId' && k!='tipoId'){
            if(typeof v === 'object'){ // GroupForms
              let attr = k.replace('atributo_','');
              let campo = this.atributos?.find(valor => valor.id.toString() === attr);
              if(v['from']!= null || v['to']!= null){
                v['from'] = (v['from']== null)?'':v['from'].replace(/,/g, '');
                v['to'] = (v['to']== null)?'':v['to'].replace(/,/g, '');
                if(Number.isInteger(Number.parseFloat(v['from']))) v['from'] =this.numberWithCommas(v['from']);
                if(Number.isInteger(Number.parseFloat(v['to']))) v['to'] = this.numberWithCommas(v['to']);
                acc[k] = campo?.nombre +': '+ v['from']+((v['from']!= '' && v['to']!= '')?'-':'')+v['to'];
              }
            }else{
              if(v!='') acc[k] = v;
              if(v==true){
                if(k.indexOf('servicio')>-1){
                  acc[k] = this.servicios?.find(i => i.id == +k.replace('servicio_','')).nombre;
                }else if(k.indexOf('amenidad')>-1){
                  acc[k] = this.amenidades?.find(i => i.id == +k.replace('amenidad_','')).nombre;           
                }else if(k.indexOf('equipamiento')>-1){
                  acc[k] = this.equipamientos?.find(i => i.id == +k.replace('equipamiento_','')).nombre;
                }else{
                  acc[k] = this.legals?.find(i => i.id == +k.replace('legals_','')).Nombre;
                }
              }
            }
          }else{
              acc[k] = v['nombre'];
          }
        }
        return acc
      },{})
    }
  }

  private numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}