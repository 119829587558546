import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthLitigioGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!localStorage.getItem('loginUser') || !localStorage.getItem('dataTime')) {
        this.router.navigate(['/login']);
        return false;
    }else{
      let expire = parseInt(localStorage.getItem('dataTime'));
      let now = Date.now();
      if(expire < now) {
        localStorage.removeItem('loginUser');
        localStorage.removeItem('dataUser');
        localStorage.removeItem('dataTime');
        this.router.navigate(['/login']);
        return false;
      }
    }

    return true;
  }
  
}