import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthLitigioGuard } from './guards/auth-litigio.guard';

export const routes: Routes = [
    { 
        path: '', 
        component: PagesComponent, children: [
            //{ path: '', redirectTo: '/landing', pathMatch: 'full' },

            { path: ':seccion/mapa', loadChildren: () => import('./pages/map-properties/map-properties.module').then(m => m.MapPropertiesModule) },
            { path: ':seccion/mapa/:tipoSlug', loadChildren: () => import('./pages/map-properties/map-properties.module').then(m => m.MapPropertiesModule) },

            { path: 'inversionistas/mapa', loadChildren: () => import('./pages/map-properties/map-properties.module').then(m => m.MapPropertiesModule),  canActivate: [AuthLitigioGuard] },
            { path: 'inversionista/mapa/:tipoSlug', loadChildren: () => import('./pages/map-properties/map-properties.module').then(m => m.MapPropertiesModule) ,  canActivate: [AuthLitigioGuard]},

            
            { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'contacto', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
            { path: 'terminos_y_condiciones', loadChildren: () => import('./pages/legales/legales.module').then(m => m.LegalesModule) },
            
            { path: 'propiedades/:tipoSlug', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule) },
            { path: 'propiedades', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule)},
            { path: 'propiedades/propiedad', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule) },
            
            { path: 'inversionistas/:tipoSlug', loadChildren: () => import('./pages/litigio/litigio.module').then(m => m.LitigioModule),  canActivate: [AuthLitigioGuard] },
            { path: 'inversionistas', loadChildren: () => import('./pages/litigio/litigio.module').then(m => m.LitigioModule),  canActivate: [AuthLitigioGuard] },
            { path: 'inversionistas/propiedad', loadChildren: () => import('./pages/litigio/litigio.module').then(m => m.LitigioModule),  canActivate: [AuthLitigioGuard] },

            { path: 'propiedades-oferta/:tipoSlug', loadChildren: () => import('./pages/ofertas/ofertas.module').then(m => m.OfertaModule)},
            { path: 'propiedades-oferta', loadChildren: () => import('./pages/ofertas/ofertas.module').then(m => m.OfertaModule)},
            { path: 'propiedades-oferta/propiedad', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule) },
            
            { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },

        ]
    },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            initialNavigation: 'enabled', // for one load page, without reload
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }