import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Amenidad, Atributo, Equipamiento, Estado, Legals, Municipio, Property, Propiedades, Servicio, Tipo } from './app.models';
import { AppSettings } from './app.settings';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { catchError, retry } from 'rxjs/operators';

export class Data {
  constructor(public properties: Property[],
              public compareList: Property[],
              public favorites: Property[],
              public tipo: Tipo[]) { }
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
 
  public url = environment.api; 
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
  
  constructor(public http:HttpClient, 
              public appSettings:AppSettings,
              public dialog: MatDialog,
              public translateService: TranslateService,
              @Inject(PLATFORM_ID) private platformId: Object) { }
    
  public getProperties(tipoId:any='', query:string='', sort:string="Nuevos", _start:number=0, _perPage:number=3, oferta, litigio): Observable<Propiedades>{
    let url = this.url + 'propiedades';
    let sorting: string;
    let params = new HttpParams();

    if(_start==null) _start = 1;

    switch (sort) {
      case 'Nuevos':
        sorting = 'created_at:DESC';
        break;
      case 'Antiguos':
        sorting = 'created_at:ASC';
        break;
      case 'Popular':
        sorting = 'visitas:DESC';
        break;
      case 'Precio (bajo a alto)':
        sorting = 'valores.atributo:12:ASC';
        break;
      case 'Precio (alto a bajo)':
        sorting = 'valores.atributo:12:DESC';
        break;
      default:
        sorting = 'created_at:DESC';
        break;
    }
    params = params.set('_sort', sorting);
    params = params.set('_start', ((_start-1)*_perPage));
    params = params.set('_limit', ((_start)*_perPage));
    if(oferta!=null)params = params.set('oferta', oferta);
    if(litigio!=null)params = params.set('litigio', litigio);
    if(tipoId){
      params = params.set('tipo.id', tipoId);
    }
    query = '&'+query;
    if(!environment.production) console.log(url+'?'+params.toString()+query);
    return this.http.get<Propiedades>(url+'?'+params.toString()+query).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getPropertiesMap(tipoId:any='', query:string='', oferta, litigio): Observable<Propiedades>{
    let url = this.url + 'propiedades';
    let params = new HttpParams();

    if(oferta!=null)params = params.set('oferta', oferta);
    if(litigio!=null)params = params.set('litigio', litigio);
    if(tipoId){
      params = params.set('tipo.id', tipoId);
    }
    query = '&'+query;
    if(!environment.production) console.log(url+'?'+params.toString()+query);
    return this.http.get<Propiedades>(url+'?'+params.toString()+query).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getRelatedProperties(zona:number, oferta, litigio, id): Observable<Propiedades>{
    let url = this.url + 'propiedades';
    let sorting: string;
    let params = new HttpParams();
    sorting = 'visitas:DESC';
    params = params.set('_sort', sorting);
    params = params.set('_start', 0);
    params = params.set('_limit', 5);
    params = params.set('zona.id', zona);
    if(oferta!=null)params = params.set('oferta', oferta);
    if(litigio!=null)params = params.set('litigio', litigio);
    params = params.set('id_ne', id);
    return this.http.get<Propiedades>(url+'?'+params.toString()).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  public getPropertyById(id, oferta:boolean, litigio:boolean): Observable<Property>{
    let params = new HttpParams();
    params = params.set('oferta', oferta);
    params = params.set('litigio', litigio);
    return this.http.get<Property>(this.url + 'propiedades/' + id +'?'+params.toString()).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getTipos(): Observable<Tipo[]>{
    return this.http.get<Tipo[]>(this.url + 'tipos').pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getAtributos(filtro:boolean, tipo_id: string = ''): Observable<Atributo[]>{

    let params = new HttpParams();
    if(filtro){params = params.set('filtro', filtro);}
    params = params.set('visible', true);
    if(tipo_id!=null && tipo_id!=''){
      params = params.set('tipos.id_in', tipo_id);
    }else{
      params = params.set('global', true);
    }

    return this.http.get<Atributo[]>(this.url + 'atributos?' + params.toString()).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getAllAtributos(): Observable<Atributo[]>{

    let params = new HttpParams();
    params = params.set('filtro', true);
    params = params.set('visible', true);

    return this.http.get<Atributo[]>(this.url + 'atributos?' + params.toString()).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getServicios(tipo_id: string = ''): Observable<Servicio[]>{

    let params = new HttpParams();
    if(tipo_id!=null && tipo_id!=''){
      params = params.set('tipos.id_in', tipo_id);
    }

    return this.http.get<Servicio[]>(this.url + 'servicios?' + params.toString()).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getAmenidades(tipo_id: string = ''): Observable<Amenidad[]>{

    let params = new HttpParams();
    if(tipo_id!=null && tipo_id!=''){
      params = params.set('tipos.id_in', tipo_id);
    }

    return this.http.get<Amenidad[]>(this.url + 'amenidades?' + params.toString()).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getEquipamientos(tipo_id: string = ''): Observable<Equipamiento[]>{

    let params = new HttpParams();
    if(tipo_id!=null && tipo_id!=''){
      params = params.set('tipos.id_in', tipo_id);
    }

    return this.http.get<Equipamiento[]>(this.url + 'equipamientos?' + params.toString()).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getLegals(): Observable<Legals[]>{

    return this.http.get<Legals[]>(this.url + 'proceso-legals').pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  public getEstados(): Observable<Estado[]>{

    return this.http.get<Estado[]>(this.url + 'estados').pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public getMunicipios(estado_id): Observable<Municipio[]>{
    return this.http.get<Municipio[]>(this.url + 'municipios?estado.id=' + estado_id ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  public sendContactPropiedad(propiedad_id, form){
    let data: any =  {
      "propiedad": propiedad_id,
      "nombre": form.nombre,
      "comentarios": form.comentarios,
      "telefono":form.telefono,
      "email": form.email
    };
    if(form.propuesta!='' && form.propuesta!=null){
      data['propuesta'] = form.propuesta;
    }
    return this.http.post<any>(this.url + 'contactos', data).pipe(
      catchError(this.handleError)
    );
  }

  public sendContacto(form){
    let data: any =  {
      "nombre": form.nombre,
      "comentarios": form.comentarios,
      "telefono":form.telefono,
      "email": form.email
    };

    return this.http.post<any>(this.url + 'contactos', data).pipe(
      catchError(this.handleError)
    );
  }

  public getPropiedadesCarousel(oferta:boolean, litigio:boolean): Observable<Propiedades>{
    let params = new HttpParams();
    params = params.set('_start', 0);
    params = params.set('_limit', 3);
    params = params.set('_sort', 'created_at:DESC');
    params = params.set('oferta', oferta);
    params = params.set('litigio', litigio);
    return this.http.get<Propiedades>(this.url+'propiedades?'+params.toString()).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  public filterData(data, page?, perPage?, total?){   
    return this.paginator(data, page, perPage, total)
  }

  public paginator(items, page?, perPage?, total?) { 
    var page = page || 1,
    perPage = perPage || 10, 
    totalPages = Math.ceil(total/perPage);
    return {
      data: items,
      pagination:{
        page: page,
        perPage: perPage,
        prePage: page - 1 ? page - 1 : null,
        nextPage: (totalPages > page) ? page + 1 : null,
        total: total,
        totalPages: totalPages,
      }
    };
  }
  
  public userAuth(form){
    let data: any =  {
      "identifier": form.email,
      "password": form.password,
    };
    return this.http.post<any>(this.url + 'auth/local', data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


}
