export class Propiedades {
    constructor(public countProperties: number,
                public propiedades: Property[]){ }
}

export class Property {
    constructor(public id: number,
                public tipo: Tipo, 
                public municipio: Municipio,
                public estado: Estado,
                public valore: string,
                public oferta: boolean,
                public litigio: boolean,
                public titulo: string,
                public fotos: Fotos[], 
                public equipamientos: string,
                public amenidades: string[],
                public servicios: string[],
                public etiquetas: string[],
                public valores: Valores[],
                public proceso_legals: Legals[],
                public zona: Zona,
                public url_video: string,
                public visitas: number,
                public coordenadas: string,
                public published_at: string,
                public created_at: string){ }
}

export class Zona {
    constructor(public id: number, 
                public nombre: string,){ }
}

export class Tipo {
    constructor(public id: number, 
                public nombre: string,
                public atributo: string[],
                public amenidade: string[],
                public equipamiento: string[],
                public servicio: string[],
                public slug: string,
                public icono: string[]){ }
}

export class Fotos {
    constructor(public id: number, 
        public name: string,
        public width: number,
        public height: number,
        public formats: string[],
        public hash: string,
        public ext: string,
        public size: string,
        public url: string){ }
}

export class Valores {
    constructor(public id: number, 
                public propiedade: string,
                public atributo: number,
                public valor: string){ }
}

export class Pagination {
    constructor(public page: number,
                public perPage: number,
                public prePage: number,
                public nextPage: number,
                public total: number,
                public totalPages: number){ }
}

export class Location {
    constructor(public propertyId: number,
                public lat: number,
                public lng: number){ }
}

export class Atributo {
    constructor(public id: number, 
                public nombre: string,
                public campo: string,
                public requerido: boolean,
                public tipo_flitro: string,
                public tipo_filtro: string,
                public filtro: boolean,
                public global: boolean,
                public visible: boolean,
                public tipos: Tipo[],){ }
}

export class Estado {
    constructor(public id: number, 
                public nombre: string,){ }
}
export class Municipio {
    constructor(public id: number, 
                public nombre: string,
                public estado: number){ }
}

export class Amenidad {
    constructor(public id: number, 
                public nombre: string,
                public filtro: boolean,
                public tipos: Tipo[]){ }
}
export class Servicio {
    constructor(public id: number, 
                public nombre: string,
                public filtro: boolean,
                public tipos: Tipo[]){ }
}
export class Equipamiento {
    constructor(public id: number, 
                public nombre: string,
                public filtro: boolean,
                public tipos: Tipo[]){ }
}
export class Legals {
    constructor(public id: number, 
                public Nombre: string,
                public filtro: boolean){ }
}

export enum Attributes { 
    Price='price',
    Address='address', 
    Colonia='suburb',
    Folio='folio'
};